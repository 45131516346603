import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SetupCompany from "../modals/SetupCompany";
import { mainFunctions } from "../providers/MainProvider";
import {
	getCategoriesAsync,
	getCompanyInfoAsync,
	getProductsAsync,
	getStoresAsync,
	getUsersAsync,
} from "../redux/actions";
import { storageService } from "../services";
import { OnboardingChecklist } from "./setup-checklist_/SetupChecklist";

export default function Sidebar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { users } = useSelector((state) => state.user);
	const { companyInfo } = useSelector((state) => state.auth);
	const { subStores } = useSelector((state) => state.store);
	const { products, categories } = useSelector((state) => state.product);
	useEffect(() => {
		if (authData.Role === "SuperAdministrator") {
			dispatch(getCompanyInfoAsync());
			dispatch(getUsersAsync());
			dispatch(getProductsAsync());
			dispatch(getCategoriesAsync());
			dispatch(getStoresAsync());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let filteredUsers = users.filter((user) => {
		return user.roleName === "Administrator";
	});
	const { showSidebar, setShowSidebar, setShowModal, setModalData } =
		useContext(mainFunctions);
	let location = useLocation();

	const authData = storageService.getAuthData();

	const [parentTab, setParentTab] = useState("");
	const toggleTab = (tab) => {
		if (parentTab === "") {
			setParentTab(tab);
		} else {
			setParentTab("");
		}
	};

	useEffect(() => {
		if (companyInfo.name) {
			if (
				authData.Role === "SuperAdministrator" &&
				(!companyInfo.name || !companyInfo.coverImage)
			) {
				setModalData(<SetupCompany />);
				setShowModal(true);
			} else {
				setShowModal(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyInfo]);

	return (
		<div
			className={`sidebar__ w-[284px] !bg-dark-gray2 py-[31px] !font-dmsans !h-screen ${
				showSidebar ? "show_sidebar" : ""
			}`}
		>
			{showSidebar && (
				<div
					className="close_menu_bar"
					onClick={() => setShowSidebar(false)}
				></div>
			)}

			<div className="h-[50px] px-[40px]">
				<div
					className="
				font-dmsans font-medium text-[15px] leading-[24px] text-light-gray1 mb-4
				"
				>
					{companyInfo.name}
				</div>
			</div>
			<div className="border-t border-dark-gray3 "></div>
			<div className="side-nav px-[40px] py-[30px] overflow-auto h-full">
				<Link to="/dashboard">
					<div
						className={`side-nav-item ${
							location.pathname === "/dashboard" ? "active" : ""
						}`}
					>
						<div className="icon dashboard"></div>
						<div className="text">Dashboard</div>
					</div>
				</Link>
				<Link to="/orders">
					<div
						className={`side-nav-item ${
							location.pathname.includes("/orders") ? "active" : ""
						}`}
					>
						<div className="icon revenue"></div>
						<div className="text">Orders</div>
					</div>
				</Link>
				{authData.Role === "Administrator" && (
					<Link to="/order/checkout">
						<div
							className={`side-nav-item ${
								location.pathname.includes("/order/checkout") ? "active" : ""
							}`}
						>
							<div className="icon revenue"></div>
							<div className="text">Checkout</div>
						</div>
					</Link>
				)}
				<Link to="/customers">
					<div
						className={`side-nav-item ${
							location.pathname.includes("/customers") ? "active" : ""
						}`}
					>
						<div className="icon customers"></div>
						<div className="text">Customers</div>
					</div>
				</Link>

				{authData.Role === "SuperAdministrator" && (
					<Link to="/categories">
						<div
							className={`side-nav-item ${
								location.pathname.includes("/categories") ? "active" : ""
							}`}
						>
							<div className="icon products"></div>
							<div className="text">Categories</div>
						</div>
					</Link>
				)}

				{authData.Role === "SuperAdministrator" && (
					<Link to="/products">
						<div
							className={`side-nav-item ${
								location.pathname === "/products" ? "active" : ""
							}`}
						>
							<div className="icon products"></div>
							<div className="text">Products</div>
						</div>
					</Link>
				)}

				{authData.Role === "Administrator" && (
					<div>
						<div
							className={`side-nav-item side-nav-item-parent 
          ${location.pathname.includes("/services") ? "active" : ""}`}
							onClick={() => navigate("/services")}
						>
							<div className="icon products"></div>
							<div className="text">Services</div>
						</div>
						<div
							className={`side-nav-item side-nav-item-parent 
            ${
							location.pathname.includes("/resources") ||
							location.pathname.includes("/products") ||
							location.pathname.includes("/tables")
								? "active"
								: ""
						}`}
							onClick={() => toggleTab("resources")}
						>
							<div className="icon products"></div>
							<div className="text">Resources</div>
							<div
								className={`right-icon ${
									parentTab === "resources" ? "up" : "down"
								}`}
							></div>
						</div>
						{parentTab === "resources" && (
							<div className="side-nav-item-child">
								<Link to="/products">
									<div
										className={`side-nav-item ${
											location.pathname === "/products" ? "active" : ""
										}`}
									>
										<div className="icon products"></div>
										<div className="text">Products</div>
									</div>
								</Link>
								<Link to="/tables">
									<div className={`side-nav-item`}>
										<div className="icon products"></div>
										Table
									</div>
								</Link>
								<Link to="/personnel">
									<div className={`side-nav-item`}>
										<div className="icon products"></div>
										Personnel
									</div>
								</Link>
							</div>
						)}
						{/* <Link to="/appointments"> */}
						<div
							className={`side-nav-item disabled ${
								location.pathname.includes("/appointments") ? "active" : ""
							}`}
						>
							<div className="icon customers"></div>
							<div className="text">Appointments</div>
						</div>
						{/* </Link> */}
					</div>
				)}

				<Link to="/stores">
					<div
						className={`side-nav-item ${
							location.pathname === "/stores" ? "active" : ""
						}`}
					>
						<div className="icon stores"></div>
						<div className="text">
							{authData.Role === "Administrator" ? "Store Cashiers" : "Stores"}
						</div>
					</div>
				</Link>

				<Link to="/mimic-logs">
					<div
						className={`side-nav-item ${
							location.pathname === "/mimic-logs" ? "active" : ""
						}`}
					>
						<div className="icon products"></div>
						<div className="text">Mimic Logs</div>
					</div>
				</Link>

				{
					authData.Role === "SuperAdministrator" && (
						// <Link to="/rewards">
						<div
							className={`side-nav-item disabled ${
								location.pathname === "/rewards" ? "active" : ""
							}`}
						>
							<div className="icon rewards"></div>
							<div className="text"> Rewards </div>
						</div>
					)
					// </Link>
				}

				{authData.Role === "SuperAdministrator" && (
					<Link to="/growth-accounting">
						<div
							className={`side-nav-item ${
								location.pathname.includes("/growth-accounting") ? "active" : ""
							}`}
						>
							<div className="icon growth-accounting"></div>
							<div className="text">Growth Accounting</div>
						</div>
					</Link>
				)}

				<Link to="/account">
					<div
						className={`side-nav-item ${
							location.pathname === "/account" ? "active" : ""
						}`}
					>
						<div className="icon account"></div>
						<div className="text">Account Settings</div>
					</div>
				</Link>
			</div>
			{authData.Role === "SuperAdministrator" &&
				(!companyInfo.name ||
					!companyInfo.coverImage ||
					!filteredUsers.length > 0 ||
					!subStores.length > 0 ||
					!categories.length > 0 ||
					!products.length > 0) && (
					<OnboardingChecklist
						collapsed={false}
						companyInfo={companyInfo}
						filteredUsers={filteredUsers}
						subStores={subStores}
						products={products}
						categories={categories}
					/>
				)}
		</div>
	);
}
