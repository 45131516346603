import React from "react";
import { formatToCurrency } from "../utils";
import { useSelector } from "react-redux";

const OfflinePurchaseDetailsModal = ({
	confirmPurchase,
	deletePurchase,
	checkPaymentStatus,
	data,
}) => {
	const { loading } = useSelector((state) => state.transaction);
	const getTotal = () => {
		let total = 0;
		if (data) total = data.checkoutData.totalAmountWithVAT;
		return total;
	};
	const handleDelete = (e) => {
		e.preventDefault();
		deletePurchase(data);
	};
	const handlePaymentStatus = (e) => {
		e.preventDefault();
		checkPaymentStatus(data);
	};
	return (
		<div className="purchase_details_modal w-100 h-100 d-flex flex-column align-items-center justify-content-center add_staff_modal">
			<div className="title">
				Purchase Details for{" "}
				<span className="uppercase">{data.checkoutData.purchaseCode}</span>
			</div>
			<p className="subtitle text-center"></p>
			<div className="pt-3 pb-0 w-100">
				<div className="total_amount rounded p-2">
					<div className="mb-4">
						<div className="text-center text-base font-medium">Bank Name</div>

						<div className="text-center font-extrabold text-2xl">VFD</div>
					</div>
					<div className="mb-4">
						<div className="text-center text-base font-medium">
							Account Number
						</div>
						<div className="text-center font-extrabold text-2xl">
							{data.checkoutData?.accountDetails?.accountNumber}
						</div>
					</div>
					<div className="mb-4">
						<div className="text-center text-base font-medium">
							Total Amount
						</div>
						<div className="text-center font-extrabold text-2xl">
							₦{formatToCurrency(getTotal(), 2)}
						</div>
					</div>
				</div>
				<div className="mt-5 w-100 d-flex align-items-center flex-column">
					<div
						className="btn_ btn_orange px-5 mb-2"
						style={{ width: "300px" }}
						onClick={!loading ? handlePaymentStatus : () => {}}
					>
						{loading ? (
							<div className="spinner-border" role="status"></div>
						) : (
							"Check Payment Status"
						)}
					</div>
					{/* <div
						className="btn_ btn_green px-5"
						style={{ width: "300px" }}
						onClick={() => confirmPurchase(data)}
					>
						Confirm Manual Payment
					</div> */}
					{/* <p 
            className='text-danger cursor-pointer mt-3'
            onClick={handleDelete}
          >Payment Not Made</p> */}
				</div>
			</div>
		</div>
	);
};

export default OfflinePurchaseDetailsModal;
