import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { storageService } from "../services";

import ChangePassword from "../pages/ChangePassword";
import AccountSettings from "../pages/AccountSettings";

// superadmin pages
import SuperAdminDashboard from "../pages/superAdmin/Dashboard";
import RevenuePage from "../pages/superAdmin/Revenue";
import CustomersPage from "../pages/superAdmin/Customers";
import SuperAdminProducts from "../pages/superAdmin/Products";
import CustomerDetailsPage from "../pages/superAdmin/CustomerDetails";
import SuperAdminTransactionDetails from "../pages/superAdmin/TransactionDetails";
import SuperAdminStores from "../pages/superAdmin/Stores";
import SuperAdminRewards from "../pages/superAdmin/Rewards";
import SuperAdminCategory from "../pages/superAdmin/Category";
import GrowthAccounting from "../pages/superAdmin/GrowthAccounting";

import AdminAppointments from "../pages/admin/Appointments";
import AdminResourceDetails from "../pages/admin/ResourceDetails";
import AppointmentDetails from "../pages/admin/AppointmentDetails";
import AdminTables from "../pages/admin/StoreTables";
import AdminPersonnel from "../pages/admin/Personnel";

// admin pages
import AdminDashboard from "../pages/admin/Dashboard";
import AdminStoreCashiers from "../pages/admin/StoreCashiers";
import AdminProducts from "../pages/admin/Products";
import AdminServices from "../pages/admin/Services";

// cashier pages
import CashierDashboard from "../pages/cashier/Dashboard";
import { useGuard } from "../hooks/useGuard";
import { getCompanyInfoAsync } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CashierTranctions from "../pages/cashier/Transactions";
import CashierTransactionDetails from "../pages/cashier/TransactionDetails";
import WhatsAppLogs from "../pages/superAdmin/WhatsappLogs";
import AdminEditOrder from "../pages/admin/EditOrder";
import AdminCheckout from "../pages/admin/Checkout";

export default function AllRoutes() {
	useGuard();
	const dispatch = useDispatch();
	const authData = storageService.getAuthData();

	const { companyInfo } = useSelector((state) => state.auth);
	useEffect(() => {
		if (authData.accessToken) {
			dispatch(getCompanyInfoAsync());
		}
	}, []);

	// effect to update favicon
	useEffect(() => {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		if (companyInfo.coverImage) {
			link.href = companyInfo.coverImage;
		}
	}, [companyInfo]);

	return (
		<div className="body">
			<Routes>
				<Route
					exact
					path="/"
					element={
						authData.Role === "Administrator" ? (
							<AdminDashboard />
						) : authData.Role === "SuperAdministrator" ? (
							<SuperAdminDashboard />
						) : (
							authData.Role === "Cashier" && <CashierDashboard />
						)
					}
				/>
				<Route exact path="/changepassword" element={<ChangePassword />} />
				<Route exact path="/account" element={<AccountSettings />} />
				<Route
					exact
					path="/dashboard"
					element={
						authData.Role ? (
							authData.Role === "Administrator" ? (
								<AdminDashboard />
							) : authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>
				<Route
					exact
					path="/orders"
					element={
						authData.Role &&
						(authData.Role === "Administrator" ||
							authData.Role === "SuperAdministrator") ? (
							<RevenuePage />
						) : (
							<Navigate to="/" />
						)
					}
				/>
				<Route
					exact
					path="/orders/:id"
					element={
						authData.Role ? (
							authData.Role === "Administrator" ? (
								<SuperAdminTransactionDetails />
							) : (
								"SuperAdministrator" && <SuperAdminTransactionDetails />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>
				<Route
					exact
					path="/order/checkout"
					element={
						authData.Role &&
						(authData.Role === "Administrator" ? (
							<AdminCheckout />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/orders/:id/edit"
					element={
						authData.Role &&
						(authData.Role === "Administrator" || "SuperAdministrator" ? (
							<AdminEditOrder />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/growth-accounting"
					element={
						authData.Role &&
						(authData.Role === "SuperAdministrator" ? (
							<GrowthAccounting />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/cashier/transactions"
					element={
						authData.Role &&
						(authData.Role === "Cashier" ? (
							<CashierTranctions />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/cashier/transaction/:id"
					element={
						authData.Role &&
						(authData.Role === "Cashier" ? (
							<CashierTransactionDetails />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/products"
					element={
						authData.Role ? (
							authData.Role === "Administrator" ? (
								<AdminProducts />
							) : (
								"SuperAdministrator" && <SuperAdminProducts />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>
				<Route
					exact
					path="/tables"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : authData.Role === "Administrator" ? (
								<AdminTables />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/personnel"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : authData.Role === "Administrator" ? (
								<AdminPersonnel />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/resource/:id"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : authData.Role === "Administrator" ? (
								<AdminResourceDetails />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/appointment/:id"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : authData.Role === "Administrator" ? (
								<AppointmentDetails />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/mimic-logs"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<WhatsAppLogs />
							) : authData.Role === "Administrator" ? (
								<WhatsAppLogs />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/appointments"
					element={
						authData.Role ? (
							authData.Role === "SuperAdministrator" ? (
								<SuperAdminDashboard />
							) : authData.Role === "Administrator" ? (
								<AdminAppointments />
							) : (
								authData.Role === "Cashier" && <CashierDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/stores"
					element={
						authData.Role ? (
							authData.Role === "Administrator" ? (
								<AdminStoreCashiers />
							) : (
								"SuperAdministrator" && <SuperAdminStores />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>
				<Route
					exact
					path="/customers"
					element={
						authData.Role &&
						(authData.Role === "Administrator" ||
							authData.Role === "SuperAdministrator") ? (
							<CustomersPage />
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/services"
					element={
						authData.Role ? (
							authData.Role === "Administrator" ? (
								<AdminServices />
							) : (
								"SuperAdministrator" && <SuperAdminDashboard />
							)
						) : (
							<Navigate to="/" />
						)
					}
				/>

				<Route
					exact
					path="/rewards"
					element={
						authData.Role &&
						(authData.Role === "SuperAdministrator" ? (
							<SuperAdminRewards />
						) : (
							<Navigate to="/" />
						))
					}
				/>
				<Route
					exact
					path="/categories"
					element={
						authData.Role &&
						(authData.Role === "SuperAdministrator" ? (
							<SuperAdminCategory />
						) : (
							<Navigate to="/dashboard" />
						))
					}
				/>
				<Route
					exact
					path="/customers/:id"
					element={
						authData.Role &&
						(authData.Role === "Administrator" ||
							authData.Role === "SuperAdministrator") ? (
							<CustomerDetailsPage />
						) : (
							<Navigate to="/" />
						)
					}
				/>
			</Routes>
		</div>
	);
}
