import * as types from "../types";

const initialState = {
	loading: false,
	error: null,
	dashboardData: {
		topStores: [],
		topSalesPeriods: [],
	},
	revenueData: {},
	transactionData: {},
	all_banks: [],
	withdrawData: {},
};

const TransactionReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_DASHBOARD_DATA.REQUEST:
		case types.GET_REVENUE_DATA.REQUEST:
		case types.CREATE_WALLET.REQUEST:
		case types.GET_BANK_CODES.REQUEST:
		case types.GET_BENEFICIARY_DETAILS.REQUEST:
		case types.TRIGGER_WITHDRAW.REQUEST:
		case types.WITHDRAW_DATA.REQUEST:
		case types.UPDATE_DELIVERY_STATUS.REQUEST:
		case types.GET_TRANSACTION_STATUS.REQUEST:
		case types.ADMIN_UPDATE_ORDER.REQUEST:
		case types.ADMIN_CANCEL_ORDER.REQUEST:
		case types.ADMIN_COMPLETE_ORDER.REQUEST:
		case types.ADMIN_CHECKOUT_ORDER.REQUEST:
			return { ...state, loading: true };
		case types.GET_TRANSACTION_DATA.REQUEST:
			return { ...state, loading: true, transactionData: {} };
		case types.GET_DASHBOARD_DATA.SUCCESS:
			return { ...state, loading: false, dashboardData: payload };
		case types.GET_REVENUE_DATA.SUCCESS:
			return { ...state, loading: false, revenueData: payload };
		case types.GET_TRANSACTION_DATA.SUCCESS:
			return { ...state, loading: false, transactionData: payload };
		case types.CREATE_WALLET.SUCCESS:
		case types.GET_BENEFICIARY_DETAILS.SUCCESS:
		case types.TRIGGER_WITHDRAW.SUCCESS:
		case types.UPDATE_DELIVERY_STATUS.SUCCESS:
		case types.GET_TRANSACTION_STATUS.SUCCESS:
		case types.ADMIN_UPDATE_ORDER.SUCCESS:
		case types.ADMIN_CANCEL_ORDER.SUCCESS:
		case types.ADMIN_COMPLETE_ORDER.SUCCESS:
		case types.ADMIN_CHECKOUT_ORDER.SUCCESS:
			return { ...state, loading: false };
		case types.GET_BANK_CODES.SUCCESS:
			return { ...state, loading: false, all_banks: payload };
		case types.WITHDRAW_DATA.SUCCESS:
			return { ...state, loading: false, withdrawData: payload };
		case types.GET_DASHBOARD_DATA.FAILURE:
		case types.GET_REVENUE_DATA.FAILURE:
		case types.GET_TRANSACTION_DATA.FAILURE:
		case types.CREATE_WALLET.FAILURE:
		case types.GET_BANK_CODES.FAILURE:
		case types.GET_BENEFICIARY_DETAILS.FAILURE:
		case types.TRIGGER_WITHDRAW.FAILURE:
		case types.WITHDRAW_DATA.FAILURE:
		case types.UPDATE_DELIVERY_STATUS.FAILURE:
		case types.GET_TRANSACTION_STATUS.FAILURE:
		case types.ADMIN_UPDATE_ORDER.FAILURE:
		case types.ADMIN_CANCEL_ORDER.FAILURE:
		case types.ADMIN_COMPLETE_ORDER.FAILURE:
		case types.ADMIN_CHECKOUT_ORDER.FAILURE:
			return { ...state, loading: false, error: payload };
		default:
			return state;
	}
};

export default TransactionReducer;
