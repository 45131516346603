import React, { useState } from "react";
import { ChecklistTask } from "./ChecklistTask";
import "./OpenChecklist.scss";
import { Button } from "../button/Button";
import cn from "classnames";
import { TextButton, SvgClose } from "@loomhq/lens";
import { storageService } from "../../services";
import { useNavigate } from "react-router-dom";

export function OpenChecklist({
	openTaskId,
	taskMap,
	closeChecklist,
	openTask,
	completeTask,
}) {
	let navigate = useNavigate();
	const { name } = storageService.getAuthData();
	const tasks = Array.from(taskMap.values());
	const [animatedTask, setAnimatedTask] = useState(null);

	const createAccount = taskMap.get("createAccount");
	const appSetup = taskMap.get("appSetup");
	const inviteAdmin = taskMap.get("inviteAdmin");
	const createStore = taskMap.get("createStore");
	const addCategory = taskMap.get("addCategory");
	const addProduct = taskMap.get("addProduct");

	const firstIncomplete = tasks.find((task) => task.isComplete === false);
	const currentTaskId = firstIncomplete?.id;

	return (
		<div className={"openChecklistContainer"}>
			<div className={"openChecklistHeader"}>
				<TextButton icon={<SvgClose />} onClick={closeChecklist}>
					Keep going, {name}!
				</TextButton>
			</div>
			<div className={cn("openChecklistTasks", "space-y--4 !bg-dark-gray2")}>
				<ol>
					<li>
						<ChecklistTask
							title={createAccount.title}
							showCompletionAnimation={animatedTask === createAccount.id}
							isCurrent={currentTaskId === createAccount.id}
							isComplete={createAccount.isComplete}
							isOpen={createAccount.id === openTaskId}
							onOpen={() => openTask(createAccount.id)}
							description={createAccount.description}
						/>
					</li>
					<li>
						<ChecklistTask
							title={appSetup.title}
							showCompletionAnimation={animatedTask === appSetup.id}
							isCurrent={currentTaskId === appSetup.id}
							isComplete={appSetup.isComplete}
							isOpen={appSetup.id === openTaskId}
							onOpen={() => openTask(appSetup.id)}
							description={appSetup.description}
						>
							<Button
								className="btn_sm btn_orange download_button"
								onClick={() => navigate("/account")}
							>
								Setup
							</Button>
						</ChecklistTask>
					</li>
					<li>
						<ChecklistTask
							title={inviteAdmin.title}
							showCompletionAnimation={animatedTask === inviteAdmin.id}
							isCurrent={currentTaskId === inviteAdmin.id}
							isComplete={inviteAdmin.isComplete}
							isOpen={inviteAdmin.id === openTaskId}
							onOpen={() => openTask(inviteAdmin.id)}
							description={inviteAdmin.description}
						>
							<a
								className="btn_sm btn_orange download_button"
								href={`${process.env.REACT_APP_MAIN_URL}settings?tab=users`}
								target="_blank"
								rel="noreferrer"
							>
								Invite
							</a>
						</ChecklistTask>
					</li>
					<li>
						<ChecklistTask
							title={createStore.title}
							showCompletionAnimation={animatedTask === createStore.id}
							isCurrent={currentTaskId === createStore.id}
							isComplete={createStore.isComplete}
							isOpen={createStore.id === openTaskId}
							onOpen={() => openTask(createStore.id)}
							description={createStore.description}
						>
							<Button
								className="btn_sm btn_orange download_button"
								onClick={() => navigate("/stores")}
							>
								Create
							</Button>
						</ChecklistTask>
					</li>
					<li>
						<ChecklistTask
							title={addCategory.title}
							showCompletionAnimation={animatedTask === addCategory.id}
							isCurrent={currentTaskId === addCategory.id}
							isComplete={addCategory.isComplete}
							isOpen={addCategory.id === openTaskId}
							onOpen={() => openTask(addCategory.id)}
							description={addCategory.description}
						>
							<Button
								className="btn_sm btn_orange download_button"
								onClick={() => navigate("/categories")}
							>
								Add
							</Button>
						</ChecklistTask>
					</li>
					<li>
						<ChecklistTask
							title={addProduct.title}
							showCompletionAnimation={animatedTask === addProduct.id}
							isCurrent={currentTaskId === addProduct.id}
							isComplete={addProduct.isComplete}
							isOpen={addProduct.id === openTaskId}
							onOpen={() => openTask(addProduct.id)}
							description={addProduct.description}
						>
							<Button
								className="btn_sm btn_orange download_button"
								onClick={() => navigate("/products")}
							>
								Add
							</Button>
						</ChecklistTask>
					</li>
				</ol>
			</div>
		</div>
	);
}
