/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import "animate.css";
import { storageService } from "../../services";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import { useGuard } from "../../hooks/useGuard";
import robot from "../../assets/mimic.svg";
import chat from "../../assets/chat.svg";
import search from "../../assets/search.svg";
import {
	getCustomersAsync,
	getStoresAsync,
	getWhatsappLogsAsync,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { FaRegUserCircle, FaRobot, FaSpinner } from "react-icons/fa";
import MimicIcon from "../../assets/mimic.svg";
import {
	toggleCustomerChatAsync,
	toggleStoreChatAsync,
} from "../../redux/actions";
import { getUserDetailsAsync } from "../../redux/actions";
import { sendMessageAsync } from "../../redux/actions";
import { useSignalR } from "../../components/SignalRContext";
import { useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { Button } from "../../components/button/Button";

// Helper function to format date
const formatDate = (date) => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

// Function to determine the date filter based on the given filterby
const getActivefilterby = (filterby) => {
	const currentDate = new Date();
	let activefilterby = { from: "", to: "" };

	if (filterby?.id) {
		switch (filterby.id) {
			case "today":
				const formattedDate = formatDate(currentDate);
				activefilterby = {
					from: `${formattedDate}T00:00:00Z`,
					to: `${formattedDate}T23:59:59Z`,
				};
				break;

			case "thisweek":
				const currentDay = currentDate.getDay();
				const startDate = new Date(currentDate);
				startDate.setDate(currentDate.getDate() - currentDay); // Start of the week
				const endDate = new Date(startDate);
				endDate.setDate(startDate.getDate() + 6); // End of the week
				activefilterby = {
					from: `${formatDate(startDate)}T00:00:00Z`,
					to: `${formatDate(endDate)}T23:59:59Z`,
				};
				break;

			case "thismonth":
				const startOfMonth = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					1
				);
				const endOfMonth = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth() + 1,
					0
				);
				activefilterby = {
					from: `${formatDate(startOfMonth)}T00:00:00Z`,
					to: `${formatDate(endOfMonth)}T23:59:59Z`,
				};
				break;

			case "thisyear":
				const startOfYear = `${currentDate.getFullYear()}-01-01T00:00:00Z`;
				const endOfYear = `${currentDate.getFullYear()}-12-31T23:59:59Z`;
				activefilterby = { from: startOfYear, to: endOfYear };
				break;

			default:
				break;
		}
	}

	return activefilterby;
};

export default function WhatsAppLogs() {
	useGuard();
	const dispatch = useDispatch();
	const location = useLocation();

	// SignalR Hooks
	const { mimicMessage, mimicOverrideRequested } = useSignalR();

	// Refs
	const msgBox = useRef(null);
	const bottomBox = useRef(null);
	// URL Parameters
	const queryParams = new URLSearchParams(location.search);
	const phoneNumber = queryParams.get("phoneNumber");

	// Redux State
	const { whatsapp_logs, loading: logsLoading } = useSelector(
		(state) => state.mimic
	);
	const { userDetails } = useSelector((state) => state.user);
	const { subStores } = useSelector((state) => state.store);

	// Local States
	const [message, setMessage] = useState("");
	const [messageLoading, setMessageLoading] = useState(false);
	const [logs, setLogs] = useState([]);
	const [selectedPhonenumber, setSelectedPhonenumber] = useState("");
	const [selectedChat, setSelectedChat] = useState({});
	const [phoneSearch, setPhoneSearch] = useState("");

	const [filterby, setFilterby] = useState({
		id: "alltime",
		title: "All Time",
	});
	const [showFilters, setShowFilters] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [canExpandPagination, setCanExpandPagination] = useState(false);
	const [showsideFilters, setShowSideFilters] = useState(false);

	const filterDates = [
		{
			id: "alltime",
			title: "All Time",
		},
		{
			id: "today",
			title: "Today",
		},
		{
			id: "thisweek",
			title: "This Week",
		},
		{
			id: "thismonth",
			title: "This Month",
		},
	];

	// Utility: Format Date and Time
	const formattedDateTime = (date) => {
		let logDate = new Date(date);
		// Format the date to "21/05/2024"
		let formattedDate = logDate.toLocaleDateString("en-GB");

		// Format the time to "15:41"
		let formattedTime = logDate.toLocaleTimeString("en-GB", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return `${formattedDate} at ${formattedTime}`;
	};

	// Fetch Logs
	const fetchLogs = async () => {
		// Get the active date filter
		const activefilterby = getActivefilterby(filterby);

		dispatch(
			getWhatsappLogsAsync({
				phoneNumber: phoneSearch,
				currentPage,
				...activefilterby,
			})
		);
	};

	// Toggle Override for Store
	const toggleOverrideStore = () => {
		if (userDetails?.subStoreId) {
			dispatch(
				toggleStoreChatAsync({ subStoreId: userDetails.subStoreId }, () => {
					dispatch(getStoresAsync());
					dispatch(getUserDetailsAsync());
				})
			);
		}
	};

	const downloadMedia = (url) => {
		// Create a URL object to easily extract the filename (if needed)
		const urlObj = new URL(url);
		const filename = urlObj.pathname.split("/").pop(); // Get the last part of the path (filename)

		// Create a new anchor element to open the URL in a new tab
		const link = document.createElement("a");
		link.href = url;
		link.target = "_blank"; // Open in a new tab
		link.rel = "noopener noreferrer"; // Security best practice
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Scroll to Bottom
	const scrollToBottom = () => {
		if (bottomBox.current) {
			bottomBox.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	const safeScrollToBottom = () => {
		setTimeout(() => {
			scrollToBottom();
		}, 100); // Adjust the delay if needed
	};

	useEffect(() => {
		safeScrollToBottom();
	}, [selectedChat?.whatsAppChats]);

	// Fetch logs when the component mounts
	useEffect(() => {
		fetchLogs();
	}, [mimicMessage, mimicOverrideRequested, filterby, phoneSearch, dispatch]);

	useEffect(() => {
		setLogs(whatsapp_logs?.data || []);
	}, [whatsapp_logs]);

	useEffect(() => {
		if (phoneNumber) {
			setSelectedPhonenumber(phoneNumber);
		}
	}, [phoneNumber]);

	useEffect(() => {
		setMessage("");
		if (selectedPhonenumber !== "") {
			const activeChat = logs.find(
				(item) => item?.phoneNumber === selectedPhonenumber
			);
			setSelectedChat(activeChat);
			scrollToBottom();
		}
	}, [selectedPhonenumber, logs]);

	useEffect(() => {
		dispatch(getUserDetailsAsync());
	}, []);

	useEffect(() => {
		dispatch(getStoresAsync());
		dispatch(getCustomersAsync());
	}, []);
	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Mimic Logs" />
				<div className="h-full p-[10px]">
					<div className="h-full w-full bg-gray-100 overflow-hidden">
						{/* <h1 className='text-2xl text-grey-800 p-[30px] font-bold shadow-md'>Eric Kayser WhatsApp Logs</h1> */}
						<div className="h-full flex flex-row">
							<div className="w-[450px] bg-dark-gray3 h-full overflow-y-auto flex flex-col">
								{showsideFilters && (
									<div
										className={`p-[20px] !pb-0 bg-dark-gray1 ${
											showsideFilters
												? "animate__animated animate__slideInDown"
												: "animate__animated animate__slideOutDown"
										}`}
									>
										<div className="flex flex-row justify-center items-center w-100 bg-dark-gray2 p-[10px] rounded-[5px]">
											<input
												className="w-full text-white bg-dark-gray2 rounded-[5px] focus:outline-none text-sm !text-dmsans"
												placeholder="Search phone number"
												onChange={(e) => setPhoneSearch(e.target.value)}
											/>
											<div>
												<img src={search} className="w-[25px]" />
											</div>
										</div>

										<div className="h-[50px] mt-[10px] mb-[20px] rounded-md flex justify-between w-full">
											<div className="mt-[6px]">
												{/* <input
													type="number"
													min={1}
													className="!w-[50px] px-[5px] bg-dark-gray2 rounded-[5px] text-[12px] leading-[30px] h-[30px] text-white"
													// defaultValue={phoneNumbersPerPage}
													onChange={
														(e) => {}
														// setPhoneNumbersPerPage(Number(e.target.value))
													}
												/>
												<div className="text-light-gray2 mt-[5px] text-[10px]">
													per page
												</div> */}
											</div>

											<div className="relative py-[10px] w-[100px]">
												<div
													className="page-filter !w-[150px] !top-0 !my-[5px] bg-dark-gray2 !h-[30px] leading-[30px] px-[15px] rounded-[5px] text-light-gray1 flex justify-between text-[12px] cursor-pointer font-dmsans"
													onClick={() => setShowFilters(!showFilters)}
												>
													<div>{filterby.title}</div>
													<div
														className={`icon !h-[30px] ${
															showFilters ? "up" : "down"
														}`}
													></div>
												</div>
												{showFilters && (
													<div className="absolute top-[40px] !bg-dark-gray1 right-[0px] page-filter-options font-dmsans">
														{filterDates.map((thisDate, index) => (
															<div
																key={index}
																className={`page-filter-option ${
																	thisDate.id === filterby.id
																		? "active !bg-primary !text-white font-dmsans"
																		: ""
																}`}
																onClick={() => setFilterby(thisDate)}
															>
																{thisDate.title}
															</div>
														))}
													</div>
												)}
											</div>
										</div>
									</div>
								)}

								{subStores[0] && (
									<div className="p-[20px] pt-0 bg-dark-gray3">
										<div
											className={`drawer w-[20px] !h-[30px] cursor-pointer rounded-md m-auto !mb-[30px] downIcon ${
												showsideFilters ? "up" : "down"
											}`}
											onClick={() => {
												if (!showsideFilters === false) setShowFilters(false);
												setShowSideFilters(!showsideFilters);
											}}
										></div>

										{subStores[0]?.overrideMimicBot !== undefined && (
											<div className="flex flex-row-reverse gap-[10px] my-[20px]">
												<div
													className={`h-[20px] w-[100px] rounded-full p-[3px] gap-[10px] flex cursor-pointer ${
														subStores[0]?.overrideMimicBot
															? "flex-row-reverse bg-white"
															: "bg-white"
													}`}
													onClick={toggleOverrideStore}
												>
													<div
														className={`w-[14px] h-[14px] rounded-full cursor-pointer ${
															subStores[0]?.overrideMimicBot
																? "bg-glow"
																: "bg-orange-600"
														}`}
													></div>
													<div className="text-[10px] font-dmsans leading-[14px]">
														{subStores[0]?.overrideMimicBot
															? "Deactivate"
															: "Activate"}
													</div>
												</div>
												<div className="font-dmsans text-white text-[12px]">
													Manual Chat
												</div>
											</div>
										)}
										{logsLoading ? (
											<div className="w-full flex items-center justify-center">
												<FaSpinner
													className="animate-spin"
													color="white"
													size={36}
												/>
											</div>
										) : (
											<>
												<div className="text-gray-400 text-sm text-dmsans">
													{logs.length} phone numbers (Page {currentPage})
												</div>

												{logs.map((customerLog, index) => {
													const customerName =
														customerLog.fullName?.length > 10
															? `${customerLog.fullName.slice(0, 10)}...`
															: customerLog.fullName;

													return (
														<div key={index}>
															<div
																className={`w-full h-auto min-h-[50px] my-2 font-dmsans text-white text-md text-center px-[15px] py-[13px] cursor-pointer rounded-[5px] bg-dark-gray1 flex justify-between ${
																	customerLog.phoneNumber ===
																	selectedPhonenumber
																		? "bg-orange-600"
																		: ""
																}`}
																onClick={() =>
																	setSelectedPhonenumber(
																		customerLog.phoneNumber
																	)
																}
															>
																<div className="text-[14px] text-left text-white saira-400">
																	{customerLog.phoneNumber.replace(
																		"whatsapp:",
																		""
																	)}
																	{!customerName.startsWith("+") && (
																		<div className="text-[10px] px-[10px] bg-dark-gray3 rounded-sm">
																			{customerName}
																		</div>
																	)}
																</div>
																<span
																	className={`flex h-[22px] mt-[1px] text-xs p-[2px] rounded-[1px] font-bold min-w-[30px] justify-center items-center ${
																		customerLog.phoneNumber ===
																		selectedPhonenumber
																			? "bg-orange-800 text-orange-300"
																			: "bg-dark-gray3 text-gray-400"
																	}`}
																>
																	{customerLog.whatsAppChats?.length}
																</span>
															</div>
														</div>
													);
												})}

												{whatsapp_logs?.totalPages > 1 && (
													<Pagination
														pages={whatsapp_logs.totalPages}
														rowsLength={whatsapp_logs.totalItems}
														_setActiveIndex={(index) => setCurrentPage(index)}
														expand={canExpandPagination}
														handleExpand={(bool) =>
															setCanExpandPagination(bool)
														}
													/>
												)}
											</>
										)}
									</div>
								)}
							</div>

							<div className="h-full bg-dark-gray2 w-full overflow-y-auto pb-[100px]">
								{selectedPhonenumber ? (
									<div className="h-full flex flex-col">
										<div className="text-dmsans text-[15px] p-[20px] text-gray-300 border-b border-gray-300 bg-opacity-70 backdrop-blur-lg">
											Logs for{" "}
											<span className="text-orange-300">
												{selectedPhonenumber}
											</span>
											<span className="text-[12px] p-[10px] bg-dark-gray2 mx-[5px] rounded-sm">
												{selectedChat?.fullName}
											</span>
										</div>

										{selectedChat?.whatsAppChats?.length > 0 ? (
											<div className="h-full flex flex-col justify-between">
												<div
													className="h-full p-[24px] overflow-y-scroll"
													ref={msgBox}
												>
													{selectedChat?.whatsAppChats.map((chat, index) => (
														<div
															key={index}
															id={
																index === selectedChat.whatsAppChats.length - 1
																	? "lastmsg"
																	: "others"
															}
															className="mb-[20px] w-full"
														>
															{chat.senderType?.toLowerCase() === "customer" ? (
																<div className="mr-auto w-fit flex gap-2 items-center text-gray-100 saira-400">
																	<FaRegUserCircle size={20} color="#F5F5F5" />
																	<div className="w-fit justify-end bg-dark-gray1 p-[10px] rounded-[5px] saira-600 text-[14px] text-gray-100">
																		{chat.body}
																	</div>
																</div>
															) : (
																<div className="text-dmsans ml-auto w-fit flex gap-2 items-center align-center text-right">
																	<div className="w-fit bg-dark-gray1 p-[10px] rounded-[5px] saira-400 text-[14px] text-gray-300 whitespace-pre-line">
																		{chat.body}
																	</div>
																	<img
																		src={MimicIcon}
																		alt="icon"
																		className="h-5"
																	/>
																</div>
															)}

															{chat.mediaUrl && chat.mediaContentType && (
																<div
																	className={`p-[10px] w-[300px] bg-dark-gray1 my-[10px] rounded-md ${
																		chat.senderType?.toLowerCase() !==
																		"customer"
																			? "ml-auto mr-[30px] text-right justify-end"
																			: "ml-[30px]"
																	}`}
																>
																	{chat.mediaContentType
																		.toLowerCase()
																		.includes("image") ? (
																		<img
																			src={chat.mediaUrl}
																			className="w-full h-[150px] object-contain"
																		/>
																	) : (
																		<div className="text-[15px] font-bold">
																			Document
																		</div>
																	)}
																	<div
																		className="text-orange-500 rounded-full cursor-pointer"
																		onClick={() => downloadMedia(chat.mediaUrl)}
																	>
																		Open{" "}
																		{chat.mediaContentType
																			.toLowerCase()
																			.includes("image")
																			? "Image"
																			: "Document"}
																	</div>
																</div>
															)}

															<div
																className={`flex text-sm p-1 ${
																	chat.senderType?.toLowerCase() !== "customer"
																		? "ml-auto mr-[30px] text-right justify-end"
																		: "ml-[30px]"
																}`}
															>
																<div className="text-[11px] text-light-gray1 text-dmsans mb-[5px] mr-[10px] rounded-sm">
																	{chat.senderType?.toLowerCase() === "customer"
																		? selectedChat?.fullName
																		: chat.senderType?.toLowerCase() === "mimic"
																		? "Mimic"
																		: "Human Agent"}
																	{" | "}
																</div>
																<div className="text-[11px] text-orange-200">
																	{formattedDateTime(chat.createdAt)}
																</div>
															</div>
														</div>
													))}
													<div ref={bottomBox} className="p-3"></div>
												</div>

												{typeof selectedChat !== "undefined" &&
													typeof userDetails !== "undefined" && (
														<div className="h-[100px] bg-dark-gray2 w-full p-[20px]">
															<div className="flex flex-row-reverse gap-[10px] my-[20px]">
																<div
																	className={`h-[20px] w-[100px] rounded-full flex p-[3px] gap-[10px] cursor-pointer ${
																		selectedChat?.overrideMimicBot
																			? "flex-row-reverse bg-white"
																			: "bg-white"
																	}`}
																	onClick={() =>
																		dispatch(
																			toggleCustomerChatAsync(
																				{
																					userPhoneNumber: selectedPhonenumber,
																				},
																				() => fetchLogs()
																			)
																		)
																	}
																>
																	<div
																		className={`w-[14px] h-[14px] rounded-full ${
																			selectedChat?.overrideMimicBot
																				? "bg-glow"
																				: "bg-orange-600"
																		}`}
																	></div>
																	<div className="text-[10px] font-dmsans">
																		{selectedChat?.overrideMimicBot
																			? "Disable"
																			: "Enable"}
																	</div>
																</div>
																<div className="font-dmsans text-white text-[12px]">
																	Manual Chat
																</div>
															</div>

															{selectedChat?.overrideMimicBot ||
															subStores[0]?.overrideMimicBot ? (
																<div className="flex gap-[10px]">
																	<textarea
																		className="w-full h-[30px] p-[5px] font-dmsans text-[12px] bg-light-gray1"
																		value={message}
																		onChange={(e) => setMessage(e.target.value)}
																	></textarea>
																	<Button
																		value="Send"
																		disabled={messageLoading}
																		className={`bg-green-500 font-dmsans w-[100px] text-center 
														h-[30px] text-white text-[12px] rounded-[3px] leading-[30pxs] cursor-pointer`}
																		onClick={() => {
																			setMessageLoading(true);
																			dispatch(
																				sendMessageAsync(
																					{
																						storeId: userDetails.storeId,
																						subStoreId: userDetails.subStoreId,
																						message,
																						recipientPhoneNumber:
																							selectedPhonenumber,
																					},
																					() => {
																						setMessage("");
																						setMessageLoading(false);
																						fetchLogs();
																						scrollToBottom();
																					},
																					() => {
																						setMessageLoading(false);
																					}
																				)
																			);
																		}}
																	>
																		{messageLoading ? (
																			<div className="w-full flex items-center justify-center">
																				<FaSpinner className="animate-spin" />
																			</div>
																		) : (
																			"Send"
																		)}
																	</Button>
																</div>
															) : (
																<div className="flex gap-[10px]">
																	<div className="w-full h-[30px] p-[5px] font-dmsans text-[12px] bg-dark-gray3"></div>
																	<div
																		className="bg-dark-gray3 font-dmsans pt-[5px] w-[100px] text-center 
														h-[30px] text-white text-[12px] rounded-[3px] leading-[30px]"
																	></div>
																</div>
															)}
														</div>
													)}
											</div>
										) : (
											<div className="p-[20px]">
												<div className="text-[16px] text-light-gray2">
													No chat logs available.
												</div>
											</div>
										)}
									</div>
								) : (
									<div className="p-[20px]">
										<div className="text-[16px] text-light-gray2">
											Select a phone number to view logs
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
