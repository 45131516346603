import React from "react";
import { useSelector } from "react-redux";
import { formatToCurrency } from "../utils";

export default function PurchaseItems({
	loading,
	activeTab,
	items,
	handleProceed,
	deletePurchase,
	deletePurchaseItem,
	decreaseQty,
	increaseQty,
}) {
	const getAmount = () => {
		if (activeTab?.checkoutData?.totalAmount) {
			return activeTab.checkoutData.totalAmount;
		} else {
			return 0;
		}
	};
	const getTotal = () => {
		if (activeTab?.checkoutData?.totalAmountWithVAT) {
			return activeTab.checkoutData.totalAmountWithVAT;
		} else {
			return 0;
		}
	};
	const getVat = () => {
		if (activeTab?.checkoutData?.vat) {
			return activeTab.checkoutData.vat;
		} else {
			return 0;
		}
	};
	return (
		<div className="d-flex flex-column gap-5 justify-content-between h-100">
			<div className="purchase_items_wrapper">
				{items &&
					items.length > 0 &&
					items.map((item, index) => (
						<div
							key={index}
							className="purchase_item d-flex justify-content-between align-items-center border-bottom py-3"
						>
							<div className="d-flex align-items-center gap-3">
								<img
									src={item.image}
									className="w-8 h-8 object-cover rounded-sm"
									alt="img"
								/>
								<div className="d-flex flex-column">
									<span className="purchase_item_title text-white">
										{item.productName}
									</span>
									<span
										className="purchase_item_delete"
										onClick={() => deletePurchaseItem(item)}
									>
										remove Item
									</span>
								</div>
							</div>
							<div className="d-flex flex-column align-items-end">
								<span className="checkout_value text-white">
									₦{formatToCurrency(item.price, 2)}
								</span>
								<div className="purchase_item_quantity !bg-glow !border-0 mt-[10px]">
									<div
										className="decrease !bg-glow !rounded-[5px]"
										onClick={() => decreaseQty(item)}
									>
										-
									</div>
									<span className="value !bg-dark-gray2 text-glow !border-0 !px-[15px] ">
										{item.quantity}
									</span>
									<div
										className="increase !bg-glow !rounded-[5px]"
										onClick={() => increaseQty(item)}
									>
										+
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
			<div className="">
				<div className="d-flex flex-column border-top py-3">
					<div className="d-flex justify-content-between">
						<p className="checkout_title text-white">Item Amount</p>
						<p className="checkout_value text-white">
							₦{formatToCurrency(getAmount(), 2)}
						</p>
					</div>
					<div className="d-flex justify-content-between">
						<p className="checkout_title text-white">VAT</p>
						<p className="checkout_value text-white">
							₦{formatToCurrency(getVat(), 2)}
						</p>
					</div>
					<div className="d-flex justify-content-between">
						<p className="checkout_title text-white">Total Amount</p>
						<p className="checkout_value text-white">
							₦{formatToCurrency(getTotal(), 2)}
						</p>
					</div>
				</div>
				<div className="d-flex flex-column align-items-center border-top gap-4 p-5">
					<div
						className="proceed_btn !bg-glow !text-dark-gray3 !rounded-full"
						onClick={!loading ? handleProceed : () => {}}
					>
						{loading ? (
							<div className="spinner-border" role="status"></div>
						) : (
							"Proceed"
						)}
					</div>
					<p className="text-danger cursor-pointer" onClick={deletePurchase}>
						Delete this Purchase
					</p>
				</div>
			</div>
		</div>
	);
}
