import * as types from "../types";

const defaultLogs = {
	data: [],
	hasNextPage: false,
	hasPreviousPage: false,
	pageIndex: 1,
	pageSize: 20,
	totalItems: 0,
	totalPages: 1,
};
const initialState = {
	loading: false,
	error: null,
	whatsapp_logs: defaultLogs,
};

const MimicReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_WHATSAPP_LOGS.REQUEST:
			return { ...state, loading: true };

		case types.GET_WHATSAPP_LOGS.SUCCESS:
			return { ...state, loading: false, whatsapp_logs: payload };

		case types.GET_WHATSAPP_LOGS.FAILURE:
			return {
				...state,
				loading: false,
				whatsapp_logs: defaultLogs,
				error: payload,
			};

		default:
			return state;
	}
};

export default MimicReducer;
