import React, { useContext, useEffect, useState } from "react";
import EmptyPurchases from "../../components/EmptyPurchases";
import Tables from "../../components/Tables";
import TopBar from "../../components/TopBar";
import { formatToCurrency, showToast } from "../../utils";
import ShoppingBag from "../../assets/icons/shopping_bag.svg";
import { useGuard } from "../../hooks/useGuard";
import { useDispatch, useSelector } from "react-redux";
import {
	adminCheckoutOrderAsync,
	getCompleteCashOrderAsync,
	getProductsAsync,
	getTransactionStatusAsync,
} from "../../redux/actions";
import Sidebar from "../../components/Sidebar";
import ActivePurchases from "../../components/ActivePurchases";
import { mainFunctions } from "../../providers/MainProvider";
import OfflinePurchaseDetailsModal from "../../modals/OfflinePurchase";

export default function AdminCheckout() {
	useGuard();
	const dispatch = useDispatch();
	const { products } = useSelector((state) => state.product);
	const { loading } = useSelector((state) => state.transaction);
	useEffect(() => {
		dispatch(getProductsAsync());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		setShowModal,
		setModalPage,
		OFFLINE_PURCHASE_DETAILS_MODAL,
		setModalData,
	} = useContext(mainFunctions);

	const [purchases, setPurchases] = useState([]);
	const [activeTab, setActiveTab] = useState("");

	const tableColumns = [
		{
			title: "Product ID",
			dataIndex: "id",
		},
		{
			title: "Product Name",
			dataIndex: "name",
			search: true,
		},
		{
			title: "Unit Price",
			dataIndex: "price",
			search: false,
		},
		{
			title: "",
			dataIndex: "action",
		},
	];
	let [filteredTableData, setFilteredTableData] = useState(products);

	const dataSource =
		filteredTableData && filteredTableData.length > 0
			? filteredTableData.map((row) => {
					return {
						id: row.id,
						name: (
							<div className="flex items-center">
								{row.coverImage && (
									<span style={{ marginRight: "11px" }}>
										<img
											src={row.coverImage}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									</span>
								)}
								<span>{row.name}</span>
							</div>
						),
						price: <div>₦{formatToCurrency(row.unitPrice, 2)}</div>,
						action: (
							<div
								className="bg-success flex w-max p-2 border rounded-circle cursor-pointer"
								onClick={() => addPurchaseItem(row)}
							>
								<img src={ShoppingBag} alt="img" />
							</div>
						),
					};
			  })
			: [];

	const checkPaymentStatus = (data) => {
		dispatch(
			getTransactionStatusAsync(data.checkoutData.purchaseCode, (res) => {
				if (res.data.paymentStatus === "Pending") {
					showToast("Pending Invoice", "info");
				} else {
					showToast("Paid Invoice", "success");
					let temp = [...purchases];
					const index = temp.indexOf(data);
					if (index > -1) temp.splice(index, 1);
					setPurchases(temp);
					if (temp.length > 0) {
						setActiveTab(temp[0]);
					}
					setShowModal(false);
				}
			})
		);
	};

	const addPurchaseItem = async (item) => {
		let temp = [...purchases];
		const index = temp.indexOf(activeTab);
		if (index > -1) {
			temp[index].items.push({
				id: item.id,
				productName: item.name,
				price: item.unitPrice,
				image: item.coverImage,
				quantity: 1,
			});
			temp[index].status = "updated";
			setPurchases(temp);
			return;
		}
		const result = await addPurchase();
		let tempPurchases = [...result];
		setActiveTab(tempPurchases[0]);
		tempPurchases[0].items.push({
			id: item.id,
			productName: item.name,
			price: item.unitPrice,
			image: item.coverImage,
			quantity: 1,
		});
		setPurchases(tempPurchases);
	};
	const addPurchase = () => {
		return new Promise((resolve) => {
			let temp = [...purchases];
			let lastPurchase = temp.slice(-1).pop();
			if (temp.length < 5) {
				temp.push({
					id: lastPurchase ? lastPurchase.id + 1 : 1,
					items: [],
					status: "updated",
					checkoutData: {},
				});
			} else {
				//show error here to delete a purchase first
			}
			setPurchases(temp);
			resolve(temp);
		});
	};
	const handleProceed = () => {
		let postData = {
			cartReq: [],
		};
		activeTab.items.map((item) =>
			postData.cartReq.push({
				quantity: item.quantity,
				productId: item.id,
				specialRequest: "",
			})
		);
		if (activeTab.status === "updated") {
			dispatch(
				adminCheckoutOrderAsync(postData, (res) => {
					let temp = [...purchases];
					const purchaseIndex = temp.indexOf(activeTab);
					temp[purchaseIndex].status = "fetched";
					setModalPage(OFFLINE_PURCHASE_DETAILS_MODAL);
					if (!loading) {
						temp[purchaseIndex].checkoutData = res;
						setPurchases(temp);
						setActiveTab(temp[purchaseIndex]);
						setModalData(
							<OfflinePurchaseDetailsModal
								confirmPurchase={confirmPurchase}
								data={activeTab}
								deletePurchase={deletePurchase}
								checkPaymentStatus={checkPaymentStatus}
							/>
						);
					}
					setShowModal(true);
				})
			);
		} else {
			setModalPage(OFFLINE_PURCHASE_DETAILS_MODAL);
			setModalData(
				<OfflinePurchaseDetailsModal
					confirmPurchase={confirmPurchase}
					data={activeTab}
					deletePurchase={deletePurchase}
					checkPaymentStatus={checkPaymentStatus}
				/>
			);
			setShowModal(true);
		}
		return;
	};
	const confirmPurchase = (data) => {
		let submitData = {
			purchaseCode: data.checkoutData.purchaseCode,
			paymentOptionId: 1,
		};
		dispatch(
			getCompleteCashOrderAsync(submitData, (res) => {
				let temp = [...purchases];
				const index = temp.indexOf(data);
				if (index > -1) temp.splice(index, 1);
				setPurchases(temp);
				if (temp.length > 0) {
					setActiveTab(temp[0]);
				}
				setShowModal(false);
			})
		);
	};
	const deletePurchase = (data) => {
		let temp = [...purchases];
		const index = temp.indexOf(data);
		if (index > -1) temp.splice(index, 1);
		setPurchases(temp);
		if (temp.length > 0) {
			setActiveTab(temp[0]);
		}
		setShowModal(false);
	};
	const deletePurchaseItem = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].status = "updated";
			temp[purchaseIndex].items.splice(itemIndex, 1);
		}
		setPurchases(temp);
	};

	const increaseQty = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].items[itemIndex].quantity++;
			temp[purchaseIndex].status = "updated";
		}
		setPurchases(temp);
	};
	const decreaseQty = (item) => {
		let temp = [...purchases];
		const purchaseIndex = temp.indexOf(activeTab);
		const itemIndex = temp[purchaseIndex].items.indexOf(item);
		if (itemIndex > -1) {
			temp[purchaseIndex].items[itemIndex].quantity =
				temp[purchaseIndex].items[itemIndex].quantity - 1;
			if (temp[purchaseIndex].items[itemIndex].quantity <= 0) {
				temp[purchaseIndex].items.splice(itemIndex, 1);
			}
			temp[purchaseIndex].status = "updated";
		}
		setPurchases(temp);
	};

	useEffect(() => {
		// const purchaseIndex = purchases.indexOf(activeTab);
		if (!activeTab && purchases.length > 0) {
			setActiveTab(purchases[0]);
		}
		if (!(purchases.length > 0)) {
			setActiveTab("");
		}
		//eslint-disable-next-line
	}, [purchases]);

	useEffect(() => {
		setFilteredTableData(products.filter((item) => item.isOutOfStock !== true));
	}, [products]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Edit Order" />
				<div className="row h-100 w-100">
					<div className="col px-5">
						<Tables
							columns={tableColumns}
							dataSource={dataSource}
							showPagination={true}
							handleSearch={true}
							source={products}
							setFilteredTableData={setFilteredTableData}
						/>
					</div>
					<div className="col bg-dark-gray1">
						{purchases.length > 0 ? (
							<ActivePurchases
								loading={loading}
								purchases={purchases}
								addPurchase={addPurchase}
								handleProceed={handleProceed}
								deletePurchase={deletePurchase}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								deletePurchaseItem={deletePurchaseItem}
								decreaseQty={decreaseQty}
								increaseQty={increaseQty}
							/>
						) : (
							<EmptyPurchases />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
